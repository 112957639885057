'use client';

import { useEffect, useState } from 'react';

import { Footer } from '@/src/components/Footer/Footer';
import { Header } from '@/src/components/Header/Header';
import { GDPRPopup } from '@/src/components/Popup';

const WebsiteLayout = ({ children }: any) => {
  const [displayShadow, setDisplayShadow] = useState(false);
  const handleScroll = () => {
    setDisplayShadow(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      <Header displayShadow={displayShadow} />
      <GDPRPopup />
      {children}
      <Footer />
    </>
  );
};

export default WebsiteLayout;
