import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { CgClose, CgMenuRightAlt } from 'react-icons/cg';

import logoImage from '@/public/images/logo.svg';
import { addParameter } from '@/src/utils/addParameter';
import { getAmplitudeDeviceId } from '@/src/utils/amplitude';
import { dashboardUrl } from '@/src/utils/processenv';

interface IProps {
  displayShadow: boolean;
}

export const Header = ({ displayShadow }: IProps) => {
  const [signupUrl, setSignupUrl] = useState(`${dashboardUrl}/signup`);
  const [loginUrl, setLoginUrl] = useState(`${dashboardUrl}/signin`);

  function updateUrls(amplitudeId: string) {
    setSignupUrl(
      addParameter(signupUrl, 'amplitudeDeviceId', amplitudeId).toString()
    );
    setLoginUrl(
      addParameter(loginUrl, 'amplitudeDeviceId', amplitudeId).toString()
    );
  }

  useEffect(() => {
    const aId = getAmplitudeDeviceId();
    if (aId) {
      updateUrls(aId);
    } else {
      setTimeout(() => {
        updateUrls(getAmplitudeDeviceId());
      }, 500);
    }
  }, []);

  const Links = [
    { name: 'Features', link: '/features/' },
    { name: 'Tutorials', link: '/tutorials/' },
    { name: 'Blog', link: '/blog/' },
    { name: 'FAQ', link: '/faqs/' },
    { name: 'Schools/Districts', link: '/schools-and-districts/' },
    { name: 'Plans', link: '/pricing/' },
    { name: 'Reviews', link: '/reviews/' },
  ];

  const [open, setOpen] = useState(false);
  const pathname = usePathname();

  return (
    <div
      className={`fixed left-0 top-0 z-50 w-full bg-white ${
        displayShadow ? 'shadow-md' : ''
      }`}
    >
      <div className="container mx-auto lg:p-4 xl:px-20">
        <div className="flex items-center gap-6">
          <div className="relative z-10 flex h-full w-full items-center justify-between bg-white px-6 py-5 lg:w-fit lg:p-0">
            <Link href="/">
              <span onClick={() => setOpen(!open)}>
                <Image
                  className="h-7 w-7 cursor-pointer md:h-[40px] md:w-[40px]"
                  src={logoImage}
                  alt="Logo"
                />
              </span>
            </Link>
            <button
              onClick={() => setOpen(!open)}
              className="lg:hidden"
              aria-label={open ? 'Close menu' : 'Open menu'}
            >
              {open ? (
                <CgClose size={32} color="#4E4E4E" />
              ) : (
                <CgMenuRightAlt size={32} color="#4E4E4E" />
              )}
            </button>
          </div>
          <div
            className={`${
              open ? 'top-0' : 'top-[-2000px] lg:top-0'
            } fixed left-0 mt-16 h-screen w-full items-center justify-between bg-white px-8 pt-3 duration-500 md:px-32 md:pt-6 lg:relative lg:mt-0 lg:flex lg:h-fit lg:px-0 lg:pt-0`}
          >
            <ul className="flex flex-col gap-4 lg:flex-row lg:flex-wrap lg:justify-center">
              {Links.map((link) => (
                <li
                  key={link.name}
                  className="text-sm md:text-[17px] lg:text-[16px]"
                  onClick={() => setOpen(!open)}
                >
                  <Link href={link.link}>
                    <span
                      className={`block bg-white ${
                        pathname === link.link
                          ? 'text-[#318AE5]'
                          : 'text-[#404040] duration-150 hover:text-[#318AE5]'
                      }`}
                    >
                      {link.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="mt-6 items-center gap-4 space-y-6 lg:mt-0 lg:flex lg:space-y-0">
              <div className="pl-1">
                <Link href="/schedule-demo">
                  <button
                    onClick={() => setOpen(!open)}
                    className="font-semibold text-[#318AE5]"
                  >
                    Book a Demo
                  </button>
                </Link>
              </div>
              <div className="flex gap-3">
                <a
                  href={loginUrl}
                  className="rounded-lg border-2 border-[#318AE5] px-7 py-2.5 font-semibold text-[#318AE5] duration-150 hover:opacity-90"
                >
                  Log In
                </a>
                <a
                  href={signupUrl}
                  className="rounded-lg border-2 border-[#318AE5] bg-[#318AE5] px-7 py-2.5 font-semibold text-white duration-150 hover:opacity-90"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
