import Link from 'next/link';
import { useEffect, useState } from 'react';

import {
  getLocalStorage,
  setLocalStorage,
  StoreKey,
} from '@/src/utils/storageHelper';

export const GDPRPopup = () => {
  const [cookieConsent, setCookieConsent] = useState<'accepted' | null>();

  const handleCookie = (value: 'accepted') => {
    setLocalStorage(StoreKey.COOKIE_CONSENT, value);
    setCookieConsent(value);
  };

  useEffect(() => {
    const value = getLocalStorage(StoreKey.COOKIE_CONSENT);
    if (value === 'accepted') {
      setCookieConsent(value);
    } else setCookieConsent(null);
  }, [cookieConsent]);
  return (
    <div
      className={`fixed duration-500 ${
        cookieConsent === null ? 'bottom-0' : '-bottom-96'
      } left-0 z-50 w-full bg-[#464E5F] py-1 text-white`}
    >
      <div className="container mx-auto flex flex-col items-center gap-6 p-6 text-sm leading-[150%] lg:flex-row lg:justify-between lg:px-14 lg:py-4">
        <p className="w-full text-center lg:text-start">
          {`We utilize cookies to provide you with the best possible experience. Your continued use of this site indicates your satisfaction.`}
        </p>
        <div className="flex items-center gap-4 whitespace-nowrap font-medium lg:gap-6">
          <Link href="/privacy-policy">
            <span className="cursor-pointer select-none leading-8 underline">
              Privacy Policy
            </span>
          </Link>
          <button
            onClick={() => handleCookie('accepted')}
            className="rounded-lg bg-white px-5 py-3 font-semibold text-[#464E5F] duration-100 hover:bg-white/90 lg:px-4 lg:py-2"
          >
            Accept Cookies
          </button>
        </div>
      </div>
    </div>
  );
};
