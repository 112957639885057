import Image from 'next/image';
import Link from 'next/link';

export const Footer = () => {
  return (
    <div className="bg-[#283859]">
      <div className="container mx-auto p-5 md:py-10 lg:px-4">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between ">
          <div className="flex flex-col md:w-full lg:ml-14 lg:w-[85%] ">
            <div className="flex items-center gap-x-4 font-medium tracking-wide text-gray-100 md:justify-center lg:justify-start">
              <Link href="/">
                <span>
                  <Image
                    src="/images/logo-blue.svg"
                    alt="AbleSpace Logo"
                    className="h-7 w-7"
                    width={28}
                    height={28}
                  />
                </span>
              </Link>
              <span className="py-3">ABLESPACE</span>
            </div>

            {/* feature and more links */}
            <div className="mt-5 flex h-64 flex-col flex-wrap gap-x-24 gap-y-5 text-white md:h-full md:w-full md:flex-row md:justify-center md:gap-x-8 lg:flex-row lg:justify-start xl:w-[85%]">
              <Link href="/features">
                <span className="text-base">Features</span>
              </Link>
              <Link href="/tutorials">
                <span className="text-base">Tutorials</span>
              </Link>
              <Link href="/blog">
                <span className="text-base">Blog</span>
              </Link>
              <Link href="/hippa">
                <span className="text-base">HIPPA</span>
              </Link>
              <Link href="/ferpa">
                <span className="text-base">FERPA</span>
              </Link>
              <Link href="/reviews">
                <span className="text-base">Reviews</span>
              </Link>
              <Link href="/faqs">
                <span className="text-base">FAQ</span>
              </Link>
              <Link href="/schools-and-districts">
                <span className="text-base">Schools/Districts</span>
              </Link>
              <Link href="/enterprise">
                <span className="text-base text-white">Enterprises</span>
              </Link>
              <Link href="/privacy-policy">
                <span className="text-base text-white">Privacy Policy</span>
              </Link>
              <Link href="/terms-of-service">
                <span className="text-base text-white">Terms of Service</span>
              </Link>
            </div>
          </div>

          {/* App Redirect Links */}

          <div className="mt-5 flex items-center justify-center gap-4 space-x-3 lg:mt-0 lg:w-6/12 lg:justify-center">
            <Link
              href="https://play.google.com/store/apps/details?id=io.ablespace.androidapp"
              className="w-[100px] md:w-[25%] xl:w-[29.5%]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex h-[52px] w-[172px] items-center justify-center gap-3 rounded-lg bg-white px-6 py-3 text-base text-grey-900">
                <Image
                  src="/images/icons/playstore-dark.svg"
                  alt="AbleSpace Android App for Tracking IEP Goals, Data Collection and Student Assessment Reports"
                  width={18}
                  height={24}
                />
                Google Play
              </button>
            </Link>

            <Link
              href="https://apps.apple.com/us/app/ablespace-iep-goal-tracking/id1662372988"
              className="w-[100px] md:w-[25%] xl:w-[29.5%]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex h-[52px] w-[172px] items-center justify-center gap-3 rounded-lg bg-white px-6 py-3 text-base text-grey-900">
                <Image
                  src="/images/icons/ios-dark.svg"
                  alt="AbleSpace iOS App for Tracking IEP Goals, Data Collection and Student Assessment Reports"
                  width={18}
                  height={24}
                />
                App Store
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* divider */}
      <div className="mx-auto mt-1 w-[90%] border border-[#3F598C]"></div>

      {/* social icons */}
      <div className="container mx-auto mt-4 w-[84%] pb-10">
        <div className="flex flex-col gap-4 md:w-full md:flex-row md:justify-between md:gap-0 md:p-0 md:py-5">
          <div className="flex items-center justify-center gap-x-4 md:order-2 md:self-end ">
            <a
              href="https://www.facebook.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/facebook/Facebook@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/facebook/Facebook@3x.png',
                    imageWidth: 0,
                    alt: 'Facebook',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/facebook/Facebook@3x.png"
                width={28}
                height={28}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/instagram/Instagram@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/instagram/Instagram@3x.png',
                    imageWidth: 0,
                    alt: 'Instagram',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/instagram/Instagram@3x.png"
                width={28}
                height={28}
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3g6DELgtautJeke1tHVf6g/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/youtube/YouTube@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/youtube/YouTube@3x.png',
                    imageWidth: 0,
                    alt: 'Youtube',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/youtube/YouTube@3x.png"
                width={28}
                height={28}
                alt="Youtube"
              />
            </a>
          </div>
          <div className="text-center text-sm text-gray-200 md:order-1">
            © 2024 AbleSpace. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
