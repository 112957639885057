export enum StoreKey {
  ID_TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  USER = 'user',
  USER_ID = 'userId',
  USER_ROLE = 'userRole',
  USER_NAME = 'userFirstName',
  USER_AVATAR = 'userAvatar',
  LAST_SESSION_URL = 'lastSessionUrl',
  COOKIE_CONSENT = 'cookieConsent',
}

export const getLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
